html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar-track {
  border: 5px solid white;
  background-color: #b2bec3;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: #dfe6e9;
}

::-webkit-scrollbar-thumb {
  background-color: #e99a01;
  border-radius: 10px;
}

.burn-tab-bg {
  background: linear-gradient(180deg, #ff7700 0%, #a31717 47.07%, #331000 100%);
}

.mint-tab-bg {
  background: linear-gradient(180deg, #333333 0%, #000000 162.42%);
}

.yellow-btn-bg {
  background: radial-gradient(
    79.38% 79.38% at 33.75% 19.38%,
    #f9ea2a 0%,
    #ee7f00 100%
  );
  position: relative;
  overflow: hidden;
}
.yellow-btn-bg:before {
  content: '';

  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(130, 91, 0, 0) 0%,
    rgba(130, 91, 0, 0.3) 100%
  );
}

.yellow-btn-bg:after {
  content: '';

  position: absolute;
  left: 2.06%;
  right: 2.06%;
  top: 0;
  height: 27px;

  background: linear-gradient(
    360deg,
    rgba(255, 255, 0, 0) -0.32%,
    #ffff00 140.72%
  );
  border-radius: 25px;
}

.red-btn-bg {
  background: linear-gradient(180deg, #ff7700 0%, #a31717 47.07%, #331000 100%);

  position: relative;
  overflow: hidden;
}
.red-btn-bg:before {
  content: '';

  /* Rectangle 3 */

  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(130, 91, 0, 0) 0%,
    rgba(130, 91, 0, 0.3) 100%
  );
}

.red-btn-bg:after {
  content: '';

  /* SPECULAR */

  position: absolute;
  left: 2.06%;
  right: 2.06%;
  top: 2%;
  bottom: 44%;

  background: linear-gradient(
    360deg,
    rgba(255, 255, 0, 0) -22.9%,
    #ff3300 132.73%
  );
  border-radius: 25px;
}
